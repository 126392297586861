"use client";
import Image from "next/image";
import Link from "next/link";
import React, { useState, useEffect, useRef } from "react";
import { usePathname } from "next/navigation";

const NavLink = [
  { name: "Эхлэл", color: "#000000", link: "/" },
  { name: "Сэдвүүд", color: "#000000", link: "/category" },
  { name: "А-Я Хайлт", color: "#000000", link: "/alphabet-filtered" },
];

export default function Header() {
  const pathname = usePathname();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const burgerRef = useRef(null);

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !burgerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef, burgerRef]);

  const isContentsPage = pathname.startsWith("/contents/");

  return (
    <header
      className={`shadow-custom3 bg-white ${
        isContentsPage ? "hidden sm:block" : ""
      }`}
    >
      <div className="container mx-auto flex justify-between h-[50px] md:h-[80px] lg:h-[110px] px-5 md:px-10">
        <Link href="/" className="flex items-center">
          <Image
            className="hidden lg:block object-cover"
            src="/logo.png"
            alt="logo"
            width={271.5}
            height={33}
          />
          <Image
            className="block lg:hidden object-cover"
            src="/logo.png"
            alt="logo"
            width={170}
            height={33}
          />
        </Link>

        <div className="flex items-center">
          {/* Desktop Links */}
          <div className="hidden md:flex items-center gap-11 md:text-[16px] lg:text-[18px] xl:text-[20px]">
            {NavLink.map((link) => {
              const isActive =
                link.link === "/"
                  ? pathname === link.link
                  : pathname.startsWith(link.link);

              return (
                <Link
                  key={link.name}
                  href={link.link}
                  className={
                    isActive
                      ? "text-[#40A4D8] font-bold"
                      : "hover:text-[#40A4D8]/80 text-[#000000] font-bold transition-colors duration-300 ease-in-out"
                  }
                >
                  {link.name}
                </Link>
              );
            })}
          </div>

          {/* Burger Menu Icon */}
          <button
            ref={burgerRef}
            className="block md:hidden flex items-center"
            onClick={() => setIsOpen(!isOpen)}
          >
            <svg
              className="w-6 h-6 text-gray-800"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
              ></path>
            </svg>
          </button>
        </div>

        {/* Mobile Menu */}
        {isOpen && (
          <div
            ref={menuRef}
            className="block md:hidden absolute top-[50px] left-0 w-full rounded-b-[24px] bg-white shadow-custom4 z-50"
          >
            <div className="flex flex-col items-center gap-3 pb-5 px-5">
              {NavLink.map((link) => {
                const isActive =
                  link.link === "/"
                    ? pathname === link.link
                    : pathname.startsWith(link.link);

                return (
                  <Link
                    key={link.name}
                    href={link.link}
                    className={`py-2 rounded-full w-full h-[44px] text-center text-base border border-b-4 ${
                      isActive ? "text-[#000000]" : ""
                    }`}
                    style={{ borderColor: link.color }}
                    onClick={() => setIsOpen(false)}
                  >
                    {link.name}
                  </Link>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </header>
  );
}
